<template>
  <div v-if="!loading">
    <predefined-exam-questions :questions="exam.questions"
                               :total-time="totalTime"
                               :lab="exam.lab"
                               :has-time="exam.has_time"
                               :has-result="exam.has_result"
                               :can-see-correct="exam.can_see_correct"
    ></predefined-exam-questions>
  </div>
</template>

<script>

import PredefinedExamQuestions from "../../components/exam/predefined/PredefinedExamQuestions";

export default {
  name: "SingleTemplateExam",
  components: {PredefinedExamQuestions,},
  data() {
    return {
      exam: null,
      totalTime: null,
      loading: true,
    }
  },
  mounted() {
    this.url = this.$route.params.url
    this.getExam()
  },
  methods: {
    getExam() {
      window.axios.get('api/exam/template/show/' + this.url).then((res) => {
        this.exam = res.data.data
        if (this.exam.has_time) {
          this.totalTime = {
            minutes: parseInt(this.exam.time / 60),
            seconds: this.exam.time - (parseInt(this.exam.time / 60) * 60)
          }
        }
        this.loading = false
      })
    },
  },
}
</script>

<style>
@font-face {
  font-family: 'Blinker-Bold';
  font-style: normal;
  src: url('../../assets/fonts/blinker/Blinker-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-Regular';
  font-style: normal;
  src: url('../../assets/fonts/blinker/Blinker-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-SemiBold';
  font-style: normal;
  src: url('../../assets/fonts/blinker/Blinker-SemiBold.ttf') format('truetype');
}

.v-dialog {
  box-shadow: 0 0 0 0 !important;
}

.v-dialog--active {
  box-shadow: unset !important;
}

.background-main {
  background-color: white !important;
}
</style>